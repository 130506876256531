import React from "react"
import { graphql } from "gatsby"
import EmblaCarousel from "../components/embla/emblaCarousel"
import get from "lodash/get"
import Img from "gatsby-image"
import Layout from "../components/layout"
import CheckLink from "../components/checkLink"
import Seo from "../components/seo"
import SliceList from "../components/sliceList"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query Page($id: String, $lang: String!) {
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
    prismicSocial(lang: { eq: $lang }) {
      ...PrismicSocialFragment
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PrismicBlogPostFragment
        }
      }
    }
    allPrismicJobPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PrismicJobPostFragment
        }
      }
    }
  }
`

const PageTemplate = (props) => {
  const pageData = props.data.prismicPage.data
  const navigation = props.data.prismicNavigation
  const social = props.data.prismicSocial
  const blogPosts = props.data.allPrismicBlogPost
  const jobPosts = props.data.allPrismicJobPost

  if (get(pageData.image_gallery[0], "page_image")) {
    var singleSource = pageData.image_gallery[0].page_image.fluid
    if (pageData.image_gallery[0].page_image.thumbnails.mobile) {
      singleSource = [
        pageData.image_gallery[0].page_image.thumbnails.mobile.fluid,
        {
          ...pageData.image_gallery[0].page_image.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    }
  }
  return (
    <Layout
      altLang={props.data.prismicPage.alternate_languages}
      navigation={navigation}
      social={social}
    >
      <Seo
        title={pageData.meta_title ? pageData.meta_title : pageData.title}
        keywords={pageData.meta_keywords}
        description={pageData.meta_description}
        lang={props.pageContext.lang}
        image={pageData.image_gallery[0]?.page_image.url}
      />
      {get(pageData.vision, "text") && (
        <div className="max-w-screen-xl w-full mx-auto text-center mt-8 mb-8 sm:mb-16 fade-in px-4 sm:px-6">
          <div
            className="vision"
            dangerouslySetInnerHTML={{ __html: get(pageData, "vision.html") }}
          />
        </div>
      )}
      {pageData.image_gallery.length > 0 && (
        <div className="relative overflow-hidden mb-10 max-w-screen-xl w-full mx-auto featured fade sm:px-6">
          {pageData.image_gallery.length >= 1 &&
            (pageData.image_gallery.length > 1 ? (
              <div className="md:rounded-xl overflow-hidden">
                <EmblaCarousel
                  autoplay={true}
                  loop={true}
                  delayLength="6000"
                  data={pageData}
                >
                  {pageData.image_gallery.map((item, index) => {
                    const sources = [
                      item.page_image.thumbnails.mobile.fluid,
                      {
                        ...item.page_image.fluid,
                        media: `(min-width: 768px)`,
                      },
                    ]
                    return (
                      <div
                        className={
                          "item relative" + (index === 0 ? " active" : "")
                        }
                        key={`item-${index}`}
                        aria-selected={index === 0 ? "true" : "false"}
                      >
                        {item.page_image && (
                          <Img
                            fluid={sources}
                            alt={item.page_image.alt}
                            style={{ filter: "brightness(.8)" }}
                          />
                        )}
                        <div
                          className={`caption absolute bottom-0 inset-x-0 z-10 p-6 md:p-12 leading-snug text-center ${
                            item.text_color === true
                              ? "text-white"
                              : "text-gray-900 "
                          }`}
                        >
                          {item.text && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: get(item, "text.html"),
                              }}
                            />
                          )}
                          {item.button_link.url && (
                            <div className="mt-auto pt-3 md:pt-6">
                              <CheckLink
                                className={`${
                                  item.button_text ? "button" : "link"
                                }`}
                                link={item.button_link}
                              >
                                {item.button_text}
                              </CheckLink>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </EmblaCarousel>
              </div>
            ) : pageData.image_gallery[0].page_image.fluid !== null ? (
              pageData.image_gallery[0].video.url !== "" ? (
                <div className=" h-full overflow-hidden bg-cover">
                  {pageData.image_gallery[0].text && (
                    <div
                      className={`caption absolute bottom-0 inset-x-0 z-10 p-6 md:p-12 leading-snug text-center ${
                        pageData.image_gallery[0].text_color === true
                          ? "text-white"
                          : "text-gray-900 "
                      }`}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(pageData.image_gallery[0], "text.html"),
                        }}
                      />
                    </div>
                  )}
                  <video
                    className="h-auto w-screen object-cover md:rounded-xl relative"
                    poster={pageData.image_gallery[0].page_image.fluid.srcWebp}
                    autoPlay
                    muted
                    playsInline
                    loop
                  >
                    <source
                      src={get(pageData.image_gallery[0].video, "url")}
                      type="video/mp4"
                    />
                  </video>
                </div>
              ) : (
                <div className="bg-white">
                  <Img
                    className="md:rounded-xl"
                    fluid={singleSource}
                    alt={pageData.image_gallery[0].page_image.alt}
                    style={{ filter: "brightness(.8)" }}
                  />
                  {pageData.image_gallery[0].text && (
                    <div
                      className={`caption absolute bottom-0 inset-x-0 z-10 p-6 md:p-12 leading-snug text-center ${
                        pageData.image_gallery[0].text_color === true
                          ? "text-white"
                          : "text-gray-900 "
                      }`}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(pageData.image_gallery[0], "text.html"),
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            ) : (
              <div
                className="caption text-center p-4 md:p-6"
                dangerouslySetInnerHTML={{
                  __html: get(pageData, "page_title.html"),
                }}
              />
            ))}
        </div>
      )}
      {pageData.body !== null &&
        pageData.body.length > 0 &&
        pageData.body.map((slice, index) => {
          return (
            <SliceList
              slice={slice}
              blogPosts={blogPosts}
              jobPosts={jobPosts}
              lang={props.pageContext.lang}
              path={props.path}
              key={`slice-${index}`}
            />
          )
        })}
    </Layout>
  )
}

export default withPreview(PageTemplate)
